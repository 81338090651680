import * as React from "react"
import {Header} from "../components/header";
import {Footer} from "../components/footer";

const PrivacyPolicy = () => {
    return (
        <main>
            <Header/>
            <div className="page">
                <div className="container">
                    <h1>Cookie Policy</h1>
                    <br/><hr/><br/>
                    <p>Like most websites, we at Influere Corp together with its subsidiaries or affiliates and any other entity, as applicable (“we” or “us”) use cookies on Fund (the “Website”). This Cookies Policy tells you about our use of cookies, and the choices you have.</p>
                    <p>This Cookie Policy is subject to, and is incorporated by reference in, our Privacy Policy which is available at <a href="https://influereinvestigations.com/privacy-policy">https://influereinvestigations.com/privacy-policy</a></p>
                    <br/><br/>
                    <h5><u>1. What Are Cookies?</u></h5>
                    <p style={{paddingLeft: '25px'}}>1.1. &nbsp;&nbsp;&nbsp;Cookies are computer files containing information which are downloaded to your computer or mobile device when you visit a website. Cookies can then be sent back to the website from which they came on each subsequent visit, or to another website that recognizes that cookie. Cookies are widely used in order to make websites work, or to work more efficiently, as well as to provide information to the owners of websites.</p>
                    <p style={{paddingLeft: '25px'}}>1.2. &nbsp;&nbsp;&nbsp;Cookies have different functions, like letting you navigate between pages efficiently, remembering your preferences, and generally improving your user experience. Cookies may tell us, for example, whether you have visited the Website before or whether you are a new visitor.</p>
                    <p style={{paddingLeft: '25px'}}>1.3. &nbsp;&nbsp;&nbsp;Cookies do not contain confidential information such as your home address, telephone number or credit card details. We do not exchange cookies with any third party websites or external data suppliers (other than as set forth below). In addition to the cookies used by us and our service providers, some cookies are placed by third parties (such as Google Analytics, for example) as further described below.</p>
                    <p style={{paddingLeft: '25px'}}>1.4. &nbsp;&nbsp;&nbsp;By using the Website, you agree to the use of cookies for the following purposes:</p>
                    <p style={{paddingLeft: '50px'}}>1.1.1. &nbsp;&nbsp;&nbsp;<u>Essential Cookies</u>: these are essential in order to enable you to move around the Website and use its features, such as accessing secure areas of the website or such areas that require recognition of registered users (a functionality that is planned to be added for the benefit of our clients).</p>
                    <p style={{paddingLeft: '50px'}}>1.1.2. &nbsp;&nbsp;&nbsp;<u>Performance and Analytics Cookies</u>: These cookies are used to store information, such as what time the current visit occurred, whether the visitor has been to the site before, and what site referred the visitor to the web page.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.3. &nbsp;&nbsp;&nbsp;<u>Functionality Cookies</u>: These cookies remember the choices you make, such as language options or your current region. These cookies help to make your visit more personal and are deleted automatically when you close your browser or the session expires.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.4. &nbsp;&nbsp;&nbsp;<u>Marketing Cookies</u>: We may use marketing cookies to deliver certain types of targeted digital marketing. We do this in order to provide you with a better user experience and to maintain, operate and continually improve the Website. The cookie stores user data and behavior information, which allows advertising services to target audience according to variables. For example, these cookies allow us to Maintain and improve the website and our products or observe the site performance and generate retargeting (which means that your may see some content of the Website and products your have browsed and indicated your interest it when you further browse the internet in ads).</p>

                    <br/><br/>
                    <h5><u>2. What Can I do to Avoid Your Cookies?</u></h5>
                    <p style={{paddingLeft: '25px'}}>2.1. &nbsp;&nbsp;&nbsp;You can block or disable cookies on your device at any time by indicating this in the preferences or options menus in your browser. You can also delete the cookies that are stored on your device. However, blocking, disabling, or deleting cookies may limit your ability to view all the pages of the Website or use online services that require registration. You can find more information about cookies at <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> and <a href="www.youronlinechoices.com">www.youronlinechoices.com</a>.</p>
                    <p style={{paddingLeft: '25px'}}>2.2. &nbsp;&nbsp;&nbsp;Most of the web browsers will provide you with some general information about cookies, enable you to see what cookies are stored on your device, allow you to delete them all or on an individual basis, and enable you to block or allow cookies for all websites or individually selected websites. You can also normally turn off third party cookies separately. Please note that the settings offered by a browser or device often only apply to that particular browser or device.</p>
                    <p style={{paddingLeft: '25px'}}>2.3. &nbsp;&nbsp;&nbsp;Information about cookies is usually found in the “Help” section of the web browser. For your convenience, below are some links to some commonly used web browsers:</p>
                    <p style={{paddingLeft: '50px'}}>1.1.5 <a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></p>
                    <p style={{paddingLeft: '50px'}}>1.1.6 <a href="https://support.google.com/chrome/answer/2392971?hl=en">Chrome for mobile</a></p>
                    <p style={{paddingLeft: '50px'}}>1.1.7 <a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">Internet Explorer and Microsoft Edge</a></p>
                    <p style={{paddingLeft: '50px'}}>1.1.8 <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></p>
                    <p style={{paddingLeft: '50px'}}>1.1.9 <a href="#">Safari</a></p>
                    <p style={{paddingLeft: '25px'}}>2.4. &nbsp;&nbsp;&nbsp;You can turn off certain third party targeting/advertising cookies by visiting the following link: Network Advertising Initiative at <a href="https://optout.networkadvertising.org">https://optout.networkadvertising.org</a>.</p>

                    <br/><br/>
                    <h5><u>3. The exact cookies we USE</u></h5>
                    <br/>
                    <table className="table table-bordered text-white" cellPadding="15">
                        <thead>
                            <tr align="center">
                                <th>Cookie Name</th>
                                <th>Domain</th>
                                <th>Type of Cookie</th>
                                <th>Description</th>
                                <th>Duration Kept</th>
                            </tr>
                        </thead>
                        <tbody align="center">
                            <tr>
                                <td>_ga</td>
                                <td>influereinvestigations.com</td>
                                <td>Analytics</td>
                                <td align="left">This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site’s analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.</td>
                                <td>2 years</td>
                            </tr>
                            <tr>
                                <td>_gid</td>
                                <td>influereinvestigations.com</td>
                                <td>Analytics</td>
                                <td align="left">This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected including the number visitors, the source where they have come from, and the pages visited in an anonymous form.</td>
                                <td>1 day</td>
                            </tr>
                            <tr>
                                <td>_gat</td>
                                <td>influereinvestigations.com</td>
                                <td>Performance</td>
                                <td align="left">This cookies is installed by Google Universal Analytics to throttle the request rate to limit the collection of data on high traffic sites.</td>
                                <td>1 minute</td>
                            </tr>
                            <tr>
                                <td>@@scroll#</td>
                                <td>influereinvestigations.com</td>
                                <td>[Please Complete]</td>
                                <td align="left">[Please Complete]</td>
                                <td>Session</td>
                            </tr>
                        </tbody>
                    </table>

                    <br/><br/>
                    <h5><u>4. Any other stuff I should know?</u></h5>
                    <p style={{paddingLeft: '25px'}}>4.1. &nbsp;&nbsp;&nbsp;<u>Web Beacons</u>: Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called “tracking pixels” or “clear gifs”). These are small graphics files that contain a unique identifier that enable us to recognize when someone has visited the Website. This allows us, for example, to monitor the traffic patterns of users from one page within our Website to another, to deliver or communicate with cookies, to understand whether a user has come to our Website from an online advertisement displayed on a third party website, to improve the Website’s performance and to measure the success of email marketing campaigns. In most instances, these technologies are reliant on cookies to function, and therefore declining cookies prevents them from functioning. If you don’t want your cookie information to be associated with your visits to these pages, you can set your browser to turn off cookies as described above. If you turn off cookies, web beacon and other technologies will still detect your visits to our Website but they will not be associated with information otherwise stored in cookies.</p>
                    <p style={{paddingLeft: '25px'}}>4.2. &nbsp;&nbsp;&nbsp;<u>Cookies Stored in the Past</u>. If you have disabled one or more Cookies, we may still use information collected from cookies prior to the date and time where you disabled such cookies (i.e. use information collected and stored up to such date and time), however, we will stop using the disabled cookie to collect any further information (i.e. no information will be collected and stored after such date and time).</p>

                    <br/><br/>
                    <h5><u>5. Questions?</u></h5>
                    <p>If you have any questions about this cookies policy, please email us at <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a>.</p>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default PrivacyPolicy

export const Head = () => <title>Influere Investigations | Privacy Policy</title>